<template>
  <content-items-list
    content-type="playlists"
    content-type-singular="playlist"
    content-name="Playlists"
    content-name-singular="Playlist"
  />
</template>

<script>
import ContentItemsList from '@/views/content-item/content-items-list/ContentItemsList.vue'

export default {
  components: {
    ContentItemsList,
  },
}
</script>

<style scoped>

</style>
