import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useContentItemsList(props) {
  // Use toast
  const toast = useToast()

  const refContentItemListTable = ref(null)

  const perPage = ref(10)
  const totalContentItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  let sortBy = ref(null)

  // Table Handlers
  let tableColumns = []
  if (props && props.contentType === 'events') {

    perPage.value = 100

    tableColumns = [
      { label: 'Event', key: 'title', sortable: true },
      { label: 'Category', key: 'category', sortable: true },
      { label: 'Date', key: 'content.start', sortable: true },
      { label: 'Last updated', key: 'updated_at', sortable: true },
      { label: 'Total Views', key: 'content.all_analytics_count', sortable: true },
      { label: 'Views (Prev 28 days)', key: 'content.last_28_days_analytics_count', sortable: true },
      { label: 'Actions', key: 'actions' },
    ]
    sortBy = ref('content.start')
  } else {
    tableColumns = [
      { label: 'Title', key: 'title', sortable: true },
      { label: 'Category', key: 'category', sortable: true },
      { label: 'Published', key: 'published_at', sortable: true },
      { label: 'Last updated', key: 'updated_at', sortable: true },
      { label: 'Total Views', key: 'content.all_analytics_count', sortable: true },
      { label: 'Views (Prev 28 days)', key: 'content.last_28_days_analytics_count', sortable: true },
      { label: 'Actions', key: 'actions' },
    ]
    sortBy = ref('updated_at')
  }


  const dataMeta = computed(() => {
    const localItemsCount = refContentItemListTable.value ? refContentItemListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalContentItems.value,
    }
  })

  const refetchData = () => {
    refContentItemListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchContentItems = (ctx, callback) => {
    store
      .dispatch('app/listContentItems', {
        contentType: props.contentType,
        queryParams: {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          status: statusFilter.value,
        },
      })
      .then(response => {
        const { results, total } = response.data

        callback(results)
        totalContentItems.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching content items.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }


  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*



  const resolveContentItemStatusVariant = status => {
    if (status) return 'success'
    if (!status) return 'secondary'
    return 'secondary'
  }

  const statusOptions = [
    { value: 0, label: 'Draft', },
    { value: 1, label: 'Published', },
  ]

  return {
    fetchContentItems,
    tableColumns,
    perPage,
    currentPage,
    totalContentItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refContentItemListTable,
    resolveContentItemStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    statusOptions,
  }
}
