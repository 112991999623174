export default {
  methods: {
    formatDate(dateString) {
      try {
        const date = new Date(dateString)
        const secondsAgo = (new Date().getTime() - date.getTime()) / 1000
        if (secondsAgo < 0) return new Intl.DateTimeFormat('en-GB', { dateStyle: 'short' }).format(date)
        if (secondsAgo < 60 * 2) return 'Just now'
        if (secondsAgo < 60 * 60) return Math.floor(secondsAgo / 60) + ' minutes ago'
        if (secondsAgo < 60 * 60 * 2) return '1 hour ago'
        if (secondsAgo < 60 * 60 * 24) return Math.floor(secondsAgo / (60 * 60)) + ' hours ago'
        if (secondsAgo < 60 * 60 * 24 * 2) return 'yesterday'
        if (secondsAgo < 60 * 60 * 24 * 7) return Math.floor(secondsAgo / (60 * 60 * 24)) + ' days ago'
        return new Intl.DateTimeFormat('en-GB', { dateStyle: 'short' }).format(date)
      } catch (e) {
        return '-'
      }
    },
  },
}
